import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Logo from "../images/logo-horizontal.svg"

const Header = ({ siteTitle }) => (
	<header className="header">
		<div>
			<Link
				to="/"
			>
				<img src={Logo} alt={siteTitle} />
			</Link>
		</div>
		<h1>TESTING</h1>
	</header>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: `Boxcars App`,
}

export default Header
